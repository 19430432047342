import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CartItem from '../components/CartItem';
import { ToastContainer, toast } from 'react-toastify';
import StripeCheckout from "react-stripe-checkout"
import axios from 'axios';
import { useDispatch } from 'react-redux'; // Add this import
import { resetCart } from '../redux/storeSlice'; // Add this import

const Cart = () => {
  const dispatch = useDispatch(); // Add this line
  const productData = useSelector((state) => state.store.productData);
  const userInfo = useSelector((state) => state.store.userInfo);
  const [totalAmt,setTotalAmt] = useState("");
  const [payNow, setPayNow] = useState(false);

  useEffect(()=>{
    let price = 0;
    productData.map((item)=>{
      price += item.price * item.quantity;
      return price;
    })
    setTotalAmt(price.toFixed(2))
  }, [productData])

  const handleCheckout = () => {
    if(userInfo){
      setPayNow(true)
    }
    else{
      toast.error("Please sign in to check out")
    }
  }

  const payment = async (token) => {
    try {
      const response = await axios.post("https://api.everestapparel.shop/pay", {
        amount: totalAmt * 100,
        token: token,
      });
  
      // Check if payment was successful
      if (response.data.success) {
        // Display success message
        toast.success("Payment successful. Redirecting...");
        // Redirect to the /orders page
        // Wait for 3 seconds before redirecting
        setTimeout(() => {
          // Redirect to the /orders page
          window.location = "https://everestapparel.shop";
          dispatch(resetCart());
        }, 3000);
      } else {
        toast.error("Payment failed. Please try again.");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      toast.error("An error occurred during payment. Please try again later.");
    }
  };

  return (
    <div>
      <img
        className="w-full h-60 object-cover"
        src="https://images.pexels.com/photos/1435752/pexels-photo-1435752.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        alt="cartImg"
      />
      <div className="max-w-screen-xl mx-auto py-20 flex">
        <CartItem/>
        <div className="w-1/3 bg-[#fafafa] py-6 px-4">
          <div className="flex flex-col gap-6 border-b-[1px] border-b-gray-400 pb-6">
            <h2 className="text-2xl font-medium">cart totals</h2>
            <p className="flex items-center gap-4 text-base">
              Subtotal{" "}
              <span className="font-titleFont font-bold text-lg">
                $ {totalAmt}
              </span>
            </p>
            <p className="flex items-start gap-4 text-base">
              Shipping{" "}
              <span className="font-titleFont font-bold text-lg">
                Free
              </span>
            </p>
          </div>
          <p className="font-titleFont font-semibold flex justify-between mt-6">
            Total <span className="text-xl font-bold">$ {totalAmt}</span>
          </p>
          <button onClick={handleCheckout} className="text-base bg-black text-white w-full py-3 my-6 hover:bg-gray-800 duration-300">
            proceed to checkout
          </button>
          {
            payNow && <div className = "w-full mt-6 flex items-center justify-center">
              <StripeCheckout
                stripeKey="pk_test_51OIFOeHjPehqhZPBXzbsF057Lmd5YiIkIi6lULT8Act0Gi3KqAKhJbNAGTWgf0qC0YCaJt2nNPEQwZOqYxmYlBo900klKKLOkM"
                name="Store Online Shopping"
                amount={totalAmt * 100}
                label="Pay to store"
                description={`Your payment amount is $${totalAmt}`}
                token={payment}
                email={userInfo.email}
              />
            </div>
          }
        </div>
        <ToastContainer
          position="top-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </div>
  )
}

export default Cart